import { z } from 'zod';

export const SipRegisterValidation = z.object({
  mail: z.string().email({ message: 'Bitte trage eine E-Mailadresse ein.' }),
  firstName: z.string().min(2),
  list: z.number()
});

export type SipRegisterBody = z.infer<typeof SipRegisterValidation>;

export enum SipList {
  MAIN = 2,
  MEDITATION = 3,
  BERGMEDITATION = 6
}
