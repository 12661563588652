// Packages
import React from 'react';
import { graphql, Link } from 'gatsby';

// Types
import type { SiteMetadata, Location } from '~types/Gatsby';
import { IGatsbyImageData } from 'gatsby-plugin-image';
import { Blog } from '~types/Blog';

// Comppnents
import Layout from '~components/layouts/Default';
import PageHeader from '~components/sections/PageHeader';
import Container from '~components/common/Container';
import H1 from '~components/typography/H1';
import H2 from '~components/typography/H2';
import P from '~components/typography/P';
import SubTitle from '~components/typography/Subtitle';
import TestmonialSingle from '~components/sections/Testmonial';
import Ul from '~components/typography/Ul';
import Grid from '~components/common/Grid';
import Image from '~components/common/Image';
import { CheckCircleIcon } from '@heroicons/react/outline';
import H3 from '~components/typography/H3';
import BlogCard from '~components/common/BlogCard';
import FaqItem from '~components/common/FaqItem';
import Bergmeditation from '~components/forms/newsletter/Bermeditation';

interface DataProps {
  site: {
    siteMetadata: SiteMetadata;
  };
  imageHeader: IGatsbyImageData;
  imageBurnout: IGatsbyImageData;
  imageBurnoutFaq: IGatsbyImageData;
  imageTestmonial: IGatsbyImageData;
  allBlog: {
    nodes: Blog[];
  };
}

export default function Page({ data, location }: { data: DataProps; location: Location }) {
  return (
    <Layout
      location={location}
      title="Der einfachste Weg zur Meditation führt durch die regelmäßige Praxis"
      desc="7 Tage begleiten wir dich zu mehr Ruhe und Gelassenheit. 7 Unterschiedliche Meditationen,  Tägliche Achtsamkeitsimpulse und 2 Live Webinare erwarten dich."
      minimalFooter={true}
    >
      <PageHeader image={data.imageHeader} classNameImage="max-w-md mx-auto animate-shake-vertical" divider>
        <span className="mb-3 inline-flex items-center rounded-full bg-brand-gray p-1 pr-2 text-white hover:text-gray-200 sm:text-base lg:text-sm xl:text-base">
          <span className="rounded-full bg-brand-green px-3 py-0.5 text-sm font-semibold leading-5 text-brand-gray">MBSR-Bergmeditation</span>
          <span className="ml-4 text-sm">Jetzt kostenfrei runterladen</span>
        </span>
        <H1 className="mb-5">Mit der gratis Bergmeditation verankerst du Ruhe und Gelassenheit und erlebst eine wunderbare Visualisierung.</H1>
        <P className="text-base sm:text-xl lg:text-lg xl:text-xl">
          Melde dich jetzt zu unserem Newsletter an und erhalte unsere beliebte Bergmeditation gratis. Mit unserem Newsletter verpasst du künftig keine Highlights mehr. Einmal im Monat wirst du über unser aktuelles Angebot und achtsame
          Impulse versorgt.
        </P>
        <div className="mt-10 sm:mt-12">
          <Bergmeditation />
        </div>
      </PageHeader>
      <Container>
        <Grid cols={2}>
          <div>
            <SubTitle className="mb-2">Achtsamkeitsbrief Anmeldung</SubTitle>
            <H2 className="mb-4 text-brand-gray">Eine liebevolle Einladung, gut zu dir zu sein.</H2>
            <Ul
              items={['Bist du manchmal zu hart zu dir?', 'Fühlst du dich manchmal so, als wärst du nicht genug?', 'Hast du sehr hohe Ansprüche an dich selbst?', 'Wünschst dir endlich mehr Gelassenheit im Umgang mit dir?']}
              className="mb-5"
            />
            <P className="mb-35">
              Dann ist unser Achtsamkeitsbrief genau das richtige für dich. Mit unserer Briefpost erhältst du liebevolle Aufforderungen für mehr Achtsamkeit und Bewusstheit. Dabei geht es ums darum, dir einen liebevollen Umgang zu
              ermöglichen, statt dich zu verurteilen oder zu ärgern.
            </P>
          </div>
          <Image imageData={data.imageBurnout} alt="Burnout" />
        </Grid>
      </Container>
      <Container>
        <div className="mx-auto max-w-2xl text-center">
          <SubTitle className="mb-2">Kein Newsletter wie jeder andere</SubTitle>
          <H2 className="mb-2">Eine liebevolle Erinnerung</H2>
          <H3 className="mb-4">Bewusstheit und Wahrnehmung ist der erste Schritt in die Veränderung</H3>
          <P className="mb-3">Du hast keine Zeit für feste Einheiten oder wiederkehrende Kurstermine? Dann bist du hier genau richtig. Mit dem Meditationsretreat der Entspannungshelden meditierst du, wann es dir passt.</P>
        </div>
        <div className="mt-12">
          <Grid cols={3}>
            {[
              {
                title: 'Aktuelle News',
                text: 'Verpasse keine Angebote,  Highlights oder Events mehr.'
              },
              {
                title: 'Kurzgeschichten',
                text: 'Liebevolle Alltagsanekdoten zum Schmunzelnund Nachdenken.'
              },
              {
                title: 'Tipps & Tricks',
                text: 'Kleine Lifehacks, die dir die Entspannung leichter machen.'
              }
            ].map((feature) => (
              <div className="pt-6 text-center" key={feature.title}>
                <div className="flow-root rounded-lg bg-gray-50 px-6 pb-8">
                  <div className="-mt-6">
                    <div className="mb-8">
                      <span className="inline-flex items-center justify-center rounded-md bg-brand-green p-3 shadow-lg">
                        <CheckCircleIcon className="h-6 w-6" />
                      </span>
                    </div>
                    <H3 as="p" className="mb-5">
                      {feature.title}
                    </H3>
                    <P>{feature.text}</P>
                  </div>
                </div>
              </div>
            ))}
          </Grid>
        </div>
      </Container>
      <TestmonialSingle
        imageData={data.imageTestmonial}
        quote="Durch den Achtsamkeitsbrief, werde ich regelmäßig an meine Rituale erinnert, die ich in meinen Alltag einbauen wollte. Es ist sehr schwer sich im Alltag immer wieder daran zu erinnern, daher ist es ganz wunderbar, dass ich montags im Büro, immer wieder einen Reminder habe. In den Kurzgeschichten finde ich mich öfters wieder und musste schon herzlich lachen."
        alt="Kundenstimme"
        author="Larissa - Kassel"
      />
      <Container>
        <div className="mx-auto max-w-2xl text-center">
          <SubTitle className="mb-2">Ich will mehr erfahren</SubTitle>
          <H2 className="mb-4">Wertvolle Ressourcen</H2>
          <P className="mb-3">
            In unserem Blog haben wir einige Artikel zum Thema mentaler Gesundheit geschrieben, in dem du alle wesentlichen Informationen bekommst, die dich motivieren zu starten.{' '}
            <Link to="/blog/" className="underline">
              Zu allen Blogbeiträgen
            </Link>
            .
          </P>
        </div>
        <div className="mt-12">
          <Grid cols={3}>
            {data.allBlog.nodes.map((entry) => {
              return <BlogCard title={entry.titel} image={entry.remoteTitelbild} link={`/blog/${entry.slug_slug}/`} text={entry.einleitung} date={entry.datum} key={entry.id} fullText={entry.text} />;
            })}
          </Grid>
        </div>
      </Container>
      <Container>
        <Grid cols={2}>
          <div>
            <span className="mb-2 font-display text-xl font-bold uppercase text-brand-green">FAQ</span>
            <H2 className="mb-4 text-brand-gray">Du hast noch Fragen?</H2>
            <dl className="space-y-3">
              {[
                {
                  frage: 'Ist das Meditationsretreat kostenfrei? ',
                  text: 'Das Retreat ist komplett kostenfrei, aber nicht umsonst. '
                },

                {
                  frage: 'Wie trage ich mich für das Meditationsretreat ein? ',
                  text: 'Du kannst dich oben mit deiner Emailadresse registrieren und bekommst einen Link zur kostenlosen Anmeldung zugesendet.  '
                },

                {
                  frage: 'Ich bin Anfänger, brauche ich irgendwas zum starten? ',
                  text: 'Nein. Es kann vom Vorteil sein, wenn du schon eine Matte und ein Yogakissen hast, ein normales Kissen und eine Decke tun es aber auch.'
                },

                {
                  frage: 'Gibt es feste Termine und Uhrzeiten? ',
                  text: 'Nein, du kannst die Meditationen üben, wann du möchtest. Morgens bekommst du dafür eine liebevolle Morgenpost, mit einer Einladung. '
                },

                {
                  frage: 'Wann startet das Retreat? ',
                  text: 'Erstmalig startet das Meditationsretreat am 01.09.2022 um 5:00 Uhr morgens, mit der Begrüßung und Einführung über Video (nicht Live) '
                }
              ].map((faq) => {
                return <FaqItem title={faq.frage} text={<p>{faq.text}</p>} key={faq.frage} />;
              })}
            </dl>
          </div>
          <Image imageData={data.imageBurnoutFaq} alt="Burnout" />
        </Grid>
      </Container>
      <Container>
        <div className="mx-auto max-w-2xl text-center">
          <span className="mb-3 inline-flex items-center rounded-full bg-brand-gray p-1 pr-2 text-white hover:text-gray-200 sm:text-base lg:text-sm xl:text-base">
            <span className="rounded-full bg-brand-green px-3 py-0.5 text-sm font-semibold leading-5 text-brand-gray">MBSR-Bergmeditation</span>
            <span className="ml-4 text-sm">Jetzt kostenfrei runterladen</span>
          </span>
          <H1 className="mb-5">Mit der gratis Bergmeditation verankerst du Ruhe und Gelassenheit und erlebst eine wunderbare Visualisierung.</H1>
          <P className="text-base sm:text-xl lg:text-lg xl:text-xl">
            {' '}
            Melde dich jetzt zu unserem Newsletter an und erhalte unsere beliebte Bergmeditation gratis. Mit unserem Newsletter verpasst du künftig keine Highlights mehr. Einmal im Monat wirst du über unser aktuelles Angebot und achtsame
            Impulse versorgt.
          </P>
          <div className="mt-10 sm:mt-12">
            <Bergmeditation />
          </div>
        </div>
      </Container>
    </Layout>
  );
}

export const query = graphql`
  {
    imageHeader: file(relativePath: { eq: "placeholder_quiz.png" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
    imageBurnout: file(relativePath: { eq: "pages/weg-zur-meditation/burnout.jpeg" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
    imageBurnoutFaq: file(relativePath: { eq: "pages/weg-zur-meditation/burnout-faq.jpeg" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
    imageTestmonial: file(relativePath: { eq: "pages/weg-zur-meditation/bild-testmonial.jpeg" }) {
      childImageSharp {
        gatsbyImageData
      }
    }

    allBlog(sort: { fields: datum, order: DESC }, limit: 6) {
      nodes {
        remoteTitelbild {
          childImageSharp {
            gatsbyImageData(quality: 80, placeholder: BLURRED)
          }
        }
        datum
        einleitung
        slug_slug
        text
        titel
        id
      }
    }
  }
`;
